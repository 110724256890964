/* fonts */

@font-face {
  font-family: fontLight;
  src: url('./Assets/Fonts/Bahnschrift/BAHNSCHRIFT\ 10.TTF');
}

@font-face {
  font-family: fontReg;
  src: url('./Assets/Fonts/Rubik/Rubik-Light.ttf');
}

@font-face {
  font-family: fontRaleLight;
  src: url('./Assets/Fonts/Raleway/static/Raleway-Medium.ttf');
}

@font-face {
  font-family: fontBold;
  src: url('./Assets/Fonts/Bahnschrift/BAHNSCHRIFT\ 10.TTF');
}

@font-face {
  font-family: fontExBold;
  src: url('./Assets/Fonts/Bahnschrift/BAHNSCHRIFT\ 10.TTF');
}

@font-face {
  font-family: asianFont;
  src: url('./Assets/Fonts/Poppins/Poppins-Medium.ttf');
}
@font-face {
  font-family: asianFontThin;
  src: url('./Assets/Fonts/Poppins/Poppins-Regular.ttf');
}


:root {
  --theme-one: #96a6eb;
  
  /* --theme-two: #8293DC; */
  --theme-two: #9187c4;

  /* --theme-three: #6b7dce; */
  --theme-three: #6969b3;
  
  /* --theme-four: #4a5db3; */
  --theme-four: #3e4c94;
  
  --theme-five: #3347a0;
  
  --theme-six: #314497;
  
  --theme-six: #24337a;
  --bg: #fff;

  --shadow: rgb(227, 232, 233);
  --shadow-two: rgb(212, 225, 226);

  --border-one: #b3b3b3;

  --footer-one: #2e3033;
  --footer-two: #151515;
}

html {
  font-size: 13px;
  /* -webkit-text-size-adjust: 80%; */
}

@media screen and (max-width: 1024px) {
  html {font-size: 14px;}
  body {
    width: 100vw;
    max-width: 100vw;
    overflow-x: hidden;
    zoom: 100%;
  }
}

body {
  margin: 0;
  box-sizing: border-box;
  font-family: fontReg;
  background-color: var(--bg);  
  scroll-behavior: smooth;  
}

h1,h2,h3,h4,h5,button {
  font-family: fontBold;
}

::placeholder {
  font-family: fontReg;
}

::-webkit-scrollbar {
  display: none;
}




@media screen and (device-aspect-ratio: 16/9) {
  body {
    zoom: 100%;
  }
}

@media screen and (device-aspect-ratio: 16/10) {
  body {
    zoom: 80%;
  }
  html {font-size: 14px;}
}