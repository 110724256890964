$bg: var(--bg);
$theme-one: var(--theme-one);
$theme-two: var(--theme-two);
$theme-three: var(--theme-three);
$theme-four: var(--theme-four);
$theme-five: var(--theme-five);
$theme-six: var(--theme-six);
$theme-seven: var(--theme-seven);

$shadow: var(--shadow);
$shadow-two: var(--shadow-two);

$border-one: var(--border-one);

$footer-one: var(--footer-one);
$footer-two: var(--footer-two);



#dpt-container {
    display: flex;
    justify-content: flex-end;
    position: fixed;
    top: 0; left: 0;
    height: 100%;
    width: 100%;
    background-color: #00000050;
    z-index: 10;
    box-sizing: border-box;
    overflow: hidden;
    animation-duration: 0.5s;
    animation-fill-mode: both;
    z-index: 25;

    #dpt-box {
        position: relative;
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 35rem;
        padding: 0 3rem 3rem 3rem;
        background-color: white;
        overflow-y: scroll;
        transform: translateX(100%);
        box-sizing: border-box;
        animation-duration: 0.5s;
        animation-fill-mode: both;
        transition: transform 0.5s ease-in-out;
        color: black;

        #dpt-box-top {
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: sticky;
            top: 0;
            padding: 3rem 0 1rem 0;
            background-color: white;

            #dpt-box-top-title {
                font-size: 2.5rem;
                font-family: fontExBold;
                color: $theme-three;
            }

            #dpt-box-close {cursor: pointer;}
        }

        #dpt-box-mid {
            margin-top: 2rem;
            font-size: 1.1rem;
        }

        #dpt-box-bottom-title {
            width: 100%;
            margin-top: 1.5rem;
            font-size: 2rem;
            font-family: fontExBold;
            color: $theme-three;
        }

        #dpt-box-bottom {
            margin-top: 1.5rem;    

            #dpt-box-scroll {
                display: flex;
                overflow-x: scroll;

                .dpt-box-dr-box {
                    display: flex;
                    flex-direction: column;
                    height: auto;
                    width: fit-content;
                    &:not(&:last-child) {margin-right: 3rem;}
    
                    .dpt-dr-img {
                        height: 17rem;
                        width: 13rem;
                        border-radius: 0.5rem;
                        // box-shadow: 3px 3px 15px 0 #eee;
                        box-sizing: border-box;
                    }
    
                    .dpt-dr-title {
                        margin-top: 1rem;
                        font-size: 1.4rem;
                        font-family: fontExBold;
                        color: $theme-three;
                    }            
                    .dpt-dr-subtitle {
                        font-size: 1rem;
                        font-family: fontReg;
                        color: $theme-three;
                    }
                }

                .dpt-box-no-value {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    width: 100%;
                    padding: 3rem 0;
                    color: #bbb;
                    
                    h2 {
                        font-size: 2rem;
                        font-weight: 100;
                        margin: 1rem 0 0 0;
                    }
                    h3 {
                        margin: 0.25rem 0 0 0;
                        font-size: 1.75rem;
                        font-weight: 100;
                    }
                }
            }
        }
    }

    @keyframes open-dpt {100% {transform: translateX(0);}}
    @keyframes close-dpt {0%{transform: translateX(0)};100% {transform: translateX(100%);}}
}
@keyframes open-dpt-cont {100% {opacity: 1;}}
@keyframes close-dpt-cont {100%{opacity: 1;};100%{opacity: 0;}}

@media screen and (max-width: 1000px) {
    #dpt-container {
        #dpt-box {
            width: 100vw;
            padding: 0 2rem 3rem 2rem;

            #dpt-box-top {
                align-items: flex-start;
                padding: 2rem 0 1rem 0;

                #dpt-box-close {margin-top: 1rem;}
            }
        }
    }
}

