$bg: var(--bg);
$theme-one: var(--theme-one);
$theme-two: var(--theme-two);
$theme-three: var(--theme-three);
$theme-four: var(--theme-four);
$theme-five: var(--theme-five);

$shadow: rgb(191, 186, 206);
$shadow-two:  rgb(168, 164, 180);

$border-one: var(--border-one);

$footer-one: var(--footer-one);
$footer-two: var(--footer-two);

#mng-container {
  height: auto;
  width: 100%;
  // margin-top: 9rem;
  padding: 2rem 13rem;
  background-color: $bg;
  // background-image: url('../../../Images/Header/logo1B-grey.svg');
  // background-color: #202020;
  box-sizing: border-box;

  #mng-header-cover {
    display: none;
  }

  #mng-top-box {
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    width: 100%;
    margin-bottom: 1rem;
    // border-bottom: 2px solid $theme-four;

    .mng-top-menu {
      position: relative;
      margin-right: 1rem;
      padding: 0.7rem 1rem;
      border-radius: 0.5rem;
      font-family: fontBold;
      font-size: 1.3rem;
      color: $theme-three;
      cursor: pointer;
      transition: color 0.3s ease-in-out;

      &:hover {
        color: $theme-four;
      }

      #mng-top-menu-active {
        position: absolute;
        bottom: 0;
        // left: 0;
        height: 8px;
        width: 90%;
        border-radius: 0.5rem;
        background-color: $theme-three;
        transition: left 0.3s ease-in-out;
      }
    }
  }

  #mgn-bottom-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: auto;
    width: 90%;
    margin: 0 auto;
    // margin-top: 2rem;
    // background-color: red;
    

    .mgn-bottom-indi {
      position: relative;
      height: 23rem;
      width: 16rem;
      margin: 0 1rem;
      border-radius: 0.5rem;
      box-sizing: border-box;
      background-size: cover;
      background-position: center;
      box-shadow: 3px 3px 15px 0 rgb(163, 163, 163);
      // box-shadow: 3px 3px 15px 0 #101010;

      &::after {
        position: absolute;
        bottom: 40%;
        left: 0;
        height: 0;
        width: 100%;
        color: white;
        opacity: 0;
        padding: 1rem;
        border-radius: 0.5rem;
        background-image: linear-gradient(to bottom, black, transparent);
        box-sizing: border-box;
        transition: height 0.5s ease-in-out, opacity 0.3s ease-in-out, bottom 0.5s ease-in-out;
      }

      // &:hover {
      //   &::after {
      //     height: 100%;
      //     opacity: 1;
      //     bottom: 0%;
      //   }
      // }

      .mgn-bottom-indi-bottom {
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
        flex-direction: column;
        position: absolute;
        bottom: 0;
        height: 60%;
        width: 100%;
        border-radius: 0.5rem;
        background-image: linear-gradient(to top, black, transparent);
        padding: 1rem;
        box-sizing: border-box;
        color: white;

        .mgn-bottom-indi-bottom-title {
          font-size: 1.4rem;
          font-weight: 900;
        }
        .mgn-bottom-indi-bottom-subtitle {
          font-size: 1.1rem;
        }
      }
    }

    #mgn-bottom-one {
      padding: 2rem 0;

      .mgn-bottom-indi {
        &:nth-child(1) {
          background-image: url('../../../Images/Management/six.jpeg');
          background-position: -5px -5px;
          &::after {
            content: 'We at Asian Poly Clinic provide the best in the industry type of service for your safekeeping. Your lives are in great hands.';
          }
          margin-bottom: 2rem;
        }
        &:nth-child(2) {
          background-image: url('../../../Images/Management/seven.jpeg');
          &::after {
            content: 'We at Asian Poly Clinic provide the best in the industry type of service for your safekeeping. Your lives are in great hands.';
          }
        }
      }
    }

    #mgn-bottom-two {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      height: 22rem;
      width: 17rem;
      font-size: 5rem;
      background-image: url("../../../Images/Header/logo1B.svg");
      background-size: contain;
      background-position: top;
      background-repeat: no-repeat;
      text-align: center;
      letter-spacing: 1rem;
      font-family: fontExBold;
      color: $theme-three;
      margin: 0;
      padding-left: 10px;
    }

    #mgn-bottom-three {
      padding: 2rem 0;

      .mgn-bottom-indi {
        &:nth-child(1) {
          background-image: url('../../../Images/Management/one.jpg');
          &::after {
            content: 'We at Asian Poly Clinic provide the best in the industry type of service for your safekeeping. Your lives are in great hands.';
          }
          margin-bottom: 2rem;
        }
        &:nth-child(2) {
          background-image: url('../../../Images/Management/four.jpg');
          &::after {
            content: 'We at Asian Poly Clinic provide the best in the industry type of service for your safekeeping. Your lives are in great hands.';
          }
        }
      }
    }
  }
}


@media screen and (max-width: 1024px) {
  #mng-container {
    position: relative;
    height: auto;
    padding: 2rem 1rem 2rem 1rem;
    margin-top: 0rem;

    #mng-header-cover {
      display: block;
      position: absolute;
      top: -4.5rem; left: 0;
      height: 4.5rem;
      width: 100%;
      background-color: $theme-three;
      box-sizing: border-box;
    }

    // #mng-bottom-box {
    //   .mng-bottom-indi-box {
    //     height: 21rem;
    //     margin: 2rem auto 0 auto;
    //     width: 16rem;
    //     background-size: cover;

    //     &:last-child {
    //       margin-bottom: 2rem;
    //     }
    //   }
    // }

    #mgn-bottom-box {
      flex-direction: column;
      justify-content: center;

      #mgn-bottom-one {
        padding: 2rem 0 0 0;
      }
      
      #mgn-bottom-two {
        display: none;
      }

      #mgn-bottom-three {
        padding: 0 0 3rem 0;
      }
    }
  }
}