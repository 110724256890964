$bg: var(--bg);
$theme-one: var(--theme-one);
$theme-two: var(--theme-two);
$theme-three: var(--theme-three);
$theme-four: var(--theme-four);
$theme-five: var(--theme-five);
$theme-six: var(--theme-six);

$border-one: var(--border-one);

$footer-one: var(--footer-one);
$footer-two: var(--footer-two);

#header-container {
  position: sticky;
  top: -4rem;
  opacity: 1;
  z-index: 14;
  box-sizing: border-box;

  #header-box-zero {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 4rem;
    width: 100%;
    padding: 0 10rem;
    background-color: #f8f8f8;
    transition: all 0.3s ease-in-out;
    box-sizing: border-box;

    .header-zero-left-box {
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 3rem;

      .header-zero-left-link {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 0.5rem;
        color: #000;
        text-decoration: none;
        opacity: 0.5;
        font-size: 1.1rem;
        font-family: Arial, Helvetica, sans-serif;

        img {
          height: 1.5rem;
          width: 1.5rem;
        }

        &:hover {
          text-decoration: underline;
        }
      }

      .header-zero-mid-box {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        height: auto;
        width: auto;
        
        .header-zero-mid-box-left {
          font-family: Arial, Helvetica, sans-serif;
          font-size: 1.1rem;
          color: #999;
        }
    
        .header-zero-mid-box-right {
          outline: none;
          border: none;
          font-family: Arial, Helvetica, sans-serif;
          font-size: 1.1rem;
          color: #999;
          cursor: pointer;
          margin-left: 0.5rem;
          
          .header-zero-branch-box {
            position: absolute; 
            left: 105%;
            top: -5%;
            height: auto;
            width: auto;
            padding: 0.1rem 0.5rem;
            border-radius: 0.25rem;
            box-shadow: 0 0 2px 0px #ccc;
            background-color: #fff;
            overflow: hidden;
    
            &:hover {
              color: #000;
              box-shadow: 0 0 3px 0px #bebebe;
            }
    
            .header-zero-branch-drop {
              animation-name: slide-in;
              animation-duration: 0.3s;
              animation-fill-mode: both;
              transform: translateX(-120%);
            }
    
            @keyframes slide-in {
              100% {transform: translateX(0%);}
            }
    
            @keyframes slide-out {
              100% {transform: translateX(-120%);}
            }
          }
        }
      }
    }

    #header-zero-right-box-social {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      column-gap: 2rem;
      height: auto;
      width: auto;
      
      .header-zero-social-icon {
        height: 1.4rem;
        width: 1.4rem;
        filter: grayscale(100%);
        opacity: 0.7;
        cursor: pointer; 
        &:hover {
          opacity: 1;
          filter: grayscale(0%);
        }
      }
    }
  }

  .header-box-one {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: auto;
    width: 100%;
    transition: all 0.3s ease-in-out;
    background-color: $bg;
    border-bottom: 1px solid #ddd;
    box-sizing: border-box;
    // box-shadow: 0px 2px 5px 0 $theme-four;

    #header-one-left-box {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      position: relative;
      height: 100%;
      width: auto;
      padding: 0rem;
      box-sizing: border-box;

      #header-one-left-top {
        position: relative;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        transform: translateY(0.25rem);
        font-family: asianFont;
        letter-spacing: 1.5px;
        color: $theme-three;
        cursor: pointer;

        &::after {
          content: 'polyclinic';
          position: absolute;
          left: 0;
          width: max-content;
          line-height: normal;
        }
      }

      #header-one-left-top-img {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 4rem;
        margin-right: 1rem;
        background-image: url('../../../Images/Header/logo1B.svg');
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
      }
    }

    .header-one-left-box-stretch {
      //small
      #header-one-left-top { 
        width: auto;
        height: 3.75rem;
        font-size: 1.9rem;
        line-height: 2.2rem;
        letter-spacing: 0px;

        &::after {
          left: 1px;
          bottom: 9px;
          font-size: 1.1rem;
          letter-spacing: 0.5px;
        }
      }
      #header-one-left-top-img { width: 2.75rem }
    }
    .header-one-left-box-shrink {
      #header-one-left-top { 
        width: 60%;
        height: 4rem;
        font-size: 2.75rem;
        line-height: 2rem;
        letter-spacing: 1.5px;
        
        &::after {
          bottom: 2px;
          font-size: 1.5rem;
          letter-spacing: 1px;
        }
      }
      #header-one-left-top-img { width: 4rem }
    }
    
    #header-one-right-box {
      height: 100%;
      width: auto;
      
      #header-one-right-bottom {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        position: relative;
        height: 100%;
        width: auto;
        padding: 0;

        // iAaZce{U#O901hs+UxMej7E$1_(.gH18
        
        .header-one-menu {
          display: flex;
          align-items: center;
          // justify-content: center;
          position: relative;
          height: 100%;
          min-width: 4rem;
          padding: 1rem;
          // font-size: 1.05rem;
          font-size: 1.1rem;
          font-family: fontExBold;
          margin: 0 0.2rem;
          cursor: pointer;
          color: grey;
          box-sizing: border-box;
          transition: background-color 0.1s ease-in-out, box-shadow 0.1s ease-in-out;

          &:hover {
            color: $bg;
            background-color: $theme-three;
            // box-shadow: inset 0px 0px 5px $theme-four;
          }
        }

        .header-one-drop-box {
          position: absolute;
          top: 100%;
          left: 0.7rem;
          // height: 16rem;
          height: 18rem;
          width: 30rem;
    
          .header-one-drop-show {
            display: flex;
            justify-content: space-between;
            position: absolute;
            top: 15%;
            left: -0.75rem;
            height: 100%;
            width: 100%;
            opacity: 1;
            background-color: $theme-three;
            // border-radius: 0.3rem;
            // box-shadow: inset 0px 0px 5px 0.5px $theme-four, 0px 0px 5px 0 #00000050;
            box-shadow: 0px 0px 10px 0 #00000050;
            animation-name: drop-menu-show;
            animation-duration: 0.4s;
            animation-fill-mode: both;

            .header-one-drop-container {
              position: relative;
              height: 100%;
              width: 50%;
              padding: 0.5rem 0;

              .header-one-drop-elements {
                position: relative;
                height: 1.5rem;
                width: 100%;
                padding: 0.5rem 1rem;
                padding-left: 1rem;
                font-size: 1rem;
                transition: padding-left 0.3s ease-in-out, color 0.3s ease-in-out;
                
                &:hover {
                  padding-left: 2rem;
                  text-shadow: 2px 2px $theme-six;
                  &::before {
                    opacity: 1;
                  }
                }

                &::before {
                  content: '-- ';
                  height: auto;
                  width: 1rem;
                  color: white;
                  opacity: 0;
                  margin-left: -1rem;
                  margin-right: 0.5rem;
                  transition: opacity 0.3s ease-in-out;
                }
              }
            }
          }
          @keyframes drop-menu-show {
            100% {
              opacity: 1;
              top: 1rem;
            }
          }
    
          .header-one-drop-hide {
            position: absolute;
            top: 1rem;
            left: -0.75rem;
            height: 100%;
            width: 100%;
            opacity: 1;
            // border-radius: 0.3rem;
            background-color: $theme-two;
            // box-shadow: 1px 1px 5px 0px $border-one;
            animation-name: drop-menu-hide;
            animation-duration: 0.4s;
            animation-fill-mode: both;

            .header-one-drop-container {
              opacity: 0;
            }
          }
          @keyframes drop-menu-hide {
            100% {
              opacity: 0;
              top: 15%;
            }
          }
        }

        #header-one-drop-two {
          width: 30rem;
          height: 11rem;
        }
      }
    }

  }

  #header-one-default {
    padding: 1.5rem 9rem 1.5rem 10rem;
  }
  
  #header-one-shrink {
    position: sticky;
    top: 0;
    padding: 0.1rem 9rem 0.1rem 10rem;
  }
}



@media screen and (max-width: 1024px) {
  #header-container {
    position: relative;
    top: 0;
    height: 4.5rem;
    z-index: 150;

    //menu-open
    .header-fix {
      position: fixed;
      top: 0;
      transition: background-color 0.4s ease-in-out;
      background-color: $theme-four;
      transition-delay: 0.4s;
      backdrop-filter: blur(4px);
    }
    .header-fix-noblur {
      position: fixed;
      transition: background-color 0.4s ease-in-out;
      background-color: $theme-four;
      transition-delay: 0.4s;
    }

    //menu-closed
    .header-abs {
      position: absolute;
      transition: all 0.4s ease-in-out;
      background-color: transparent;
      backdrop-filter: blur(5px);
    }
    .header-abs-noblur {
      position: absolute;
      transition: background-color 0.4s ease-in-out, background-image 0.4s ease-in-out;
      background-color: transparent;
    }


    
    #header-box-two {
      display: flex;
      align-items: center;
      flex-flow: row wrap;
      position: absolute;
      top: 0;
      height: 4.5rem;
      width: 100%;
      box-shadow: 0 1px 5px 0 transparent;
      z-index: 150;
  
      .header-two-left-box {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 100%;
        width: 80%;
        box-sizing: border-box;
        cursor: pointer;
  
        .header-two-logo {
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          position: relative;
          transform: translateY(0.5rem);
          font-family: asianFontThin;
          letter-spacing: 1.5px;
          color: #fff;
          cursor: pointer;
          width: auto;
          height: 4rem;
          font-size: 2rem;
          line-height: 2rem;
          letter-spacing: 1.5px;

          &::after {
            content: 'polyclinic';
            position: absolute;
            left: 1px;
            bottom: 11px;
            font-size: 1.1rem;
            letter-spacing: 1px;
            width: max-content;
            line-height: normal;
          }        
        }

        .header-two-img {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 3rem;
          width: 4rem;
          margin-bottom: 0rem;
          margin-left: 0.5rem;
          background-image: url('../../../Images/Header/logo1.svg');
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
        }
      }
  
      #header-two-right-menu-box {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 20%;
        box-sizing: border-box;

        #header-two-right-menu {
          height: 1.9rem;
          width: 1.9rem;
          cursor: pointer;
        }
      }


      #header-two-right-menu-drop {
        position: fixed;
        height: calc(100vh - 4.45rem);
        width: 100%;
        top: 4.45rem;
        left: 0;
        background-color: $theme-four;
        z-index: 150;

        .phone-menu-top {
          height: 80%;
          width: 100%;
          padding: 1rem;
          box-sizing: border-box;

          .phone-two-menu-elements {
            padding: 1rem 0.5rem;
            font-size: 1.05rem;
            color: #fff;
            font-family: fontLight;
          }

          .phone-two-menu-elements {
            height: auto;
            padding: 1rem 0.5rem;
            font-size: 1.05rem;
            color: #fff;
            font-family: fontLight;
            transition: padding-left 0.3s ease-in-out, color 0.3s ease-in-out;
            cursor: pointer;
            
            &:hover {
              padding-left: 1rem;
              color: $theme-two;
              &::before {
                opacity: 1;
              }
            }
  
            &::before {
              content: '-- ';
              height: auto;
              width: 1rem;
              color: $theme-two;
              opacity: 0;
              margin-left: -1rem;
              margin-right: 0.5rem;
              transition: opacity 0.3s ease-in-out;
            }
  
            .phone-two-menu-drop-box {
              position: relative;
              height: auto;
              width: 100%;
              padding: 1rem 0rem 0 2rem;
              box-sizing: border-box;
  
              .phone-two-menu-dropp {
                height: auto;
                width: 100%;
                transition: height 0.4s ease-in-out;
                animation-duration: 0.4s;
                animation-fill-mode: both;
  
                .phone-two-menu-drop-elements {
                  padding: 0.5rem 0;
                  font-size: 1.05rem;
                  color: #fff;
                  transition: padding-left 0.3s ease-in-out, color 0.3s ease-in-out;
                  cursor: pointer;
            
                  &:hover {
                    padding-left: 1rem;
                    color: $theme-two;
                    &::before {
                      opacity: 1;
                    }
                  }
  
                  &::before {
                    content: '-- ';
                    height: auto;
                    width: 1rem;
                    color: $theme-two;
                    opacity: 0;
                    margin-left: -1rem;
                    margin-right: 0.5rem;
                    transition: opacity 0.3s ease-in-out;
                  }
                }
              }
            }
          }
        }
        .phone-menu-bottom {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          height: 20%;
          width: 100%;
          padding: 0rem 2rem;
          box-sizing: border-box;

          .header-one-social-icon {
            height: 1.5rem;
            width: 1.5rem;
            padding-right: 2rem;
            cursor: pointer; 
            &:hover {filter: grayscale(100%);}
          }
        }
      }
      .header-drop-menu {
        position: relative;
        opacity: 0;
        transform: scale(0, 0);
        transform-origin: 100% 0%;
        animation-duration: 0.8s;
        animation-fill-mode: both;
        // backdrop-filter: blur(5px);
        animation-name: header-menu-mount;
      }
      .header-hide-menu {
        opacity: 1;
        transform: scale(1, 1);
        transform-origin: 100% 0%;
        background-color: $theme-four;
        animation-duration: 0.8s;
        animation-fill-mode: both;
        animation-name: header-menu-unmount;
      }
      @keyframes header-menu-mount {
        50% {
          transform: scale(0.0, 1); //0.1 1
          opacity: 1;
        }
        100% {
          transform: scale(1, 1);
          opacity: 1;
        }
      }
      @keyframes header-menu-unmount {
        50% {
          transform: scale(0.0, 1);
          opacity: 1;
        }
        100% {
          transform: scale(0, 0);
          opacity: 0;
        }
      }
    }
  }
}


// head //

#header-container #header-one-default{
  position: absolute;
  top: 52px;
  background: transparent;
  border-bottom: 1px solid rgba(173, 173, 173, 0.197);
}

#header-container .header-box-one #header-one-right-box #header-one-right-bottom .header-one-menu{
  color: white;
}


#header-container .header-box-one #header-one-right-box #header-one-right-bottom .header-one-menu {
  color: rgb(103, 103, 103);
  transition: color 0.3s; 
}

#header-container.scrolled .header-box-one #header-one-right-box #header-one-right-bottom .header-one-menu {
  color: grey;
}


#header-container.scrolled .header-box-one #header-one-right-box #header-one-right-bottom .header-one-menu:hover {
  color: rgb(255, 255, 255);
}

@media(max-width:1024px){

#header-container #header-box-two .header-two-left-box .header-two-img{
  background-image: url('../../../Images/Header/logo1B.svg');
}
#header-container #header-box-two .header-two-left-box .header-two-logo{
color: var(--theme-three);;
}
}