$bg: var(--bg);
$theme-one: var(--theme-one);
$theme-two: var(--theme-two);
$theme-three: var(--theme-three);
$theme-four: var(--theme-four);
$theme-five: var(--theme-five);

$shadow: rgb(191, 186, 206);
$shadow-two:  rgb(168, 164, 180);

$border-one: var(--border-one);

$footer-one: var(--footer-one);
$footer-two: var(--footer-two);

#dr-container {
  height: auto;
  width: 100%;
  // margin-top: 9rem;
  padding: 4rem 9rem;
  background-color: $bg;
  box-sizing: border-box;

  #dr-header-cover {
    display: none;
  }

  #dr-top-box {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: auto;
    width: 100%;
    margin-bottom: 1rem;
    // border-bottom: 2px solid $theme-four;

    .dr-top-menu {
      position: relative;
      margin-right: 1rem;
      padding: 0.7rem 1rem;
      border-radius: 0.5rem;
      font-family: fontBold;
      font-size: 1.3rem;
      color: $theme-three;
      cursor: pointer;
      transition: color 0.3s ease-in-out;

      &:hover {
        color: $theme-four;
      }

      #dr-top-menu-active {
        position: absolute;
        bottom: 0;
        // left: 0;
        height: 8px;
        width: 90%;
        border-radius: 0.5rem;
        background-color: $theme-three;
        transition: left 0.3s ease-in-out;
      }
    }
  }

  #dr-bottom-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 2rem;
    .dr-bottom-indi-box {
      position: relative;
      height: 20rem;
      width: 15rem;
      margin: 1rem;
      border-radius: 0.5rem;
      box-shadow: 3px 3px 15px 0 $shadow;
      cursor: default;

      &:hover {
        box-shadow: 3px 3px 15px 0 $shadow-two;
      }

      .dr-indi-img {
        height: 100%;
        width: 100%;
        border-radius: 0.5rem;
      }

      .dr-bottom-indi-bottom {
        display: flex;
        flex-direction: column;
        justify-content: end;
        position: absolute;
        top: 50%;
        height: 50%;
        width: 100%;
        padding: 0 1rem 1rem 1rem;
        border-radius: 0.5rem;
        color: white;
        background-image: linear-gradient(to top, $theme-four, transparent);
        box-sizing: border-box;
        transition: top 0.5s ease-in-out, backround-image 0.5s ease-in-out, height 0.5s ease-in-out, color 0.5s ease-in-out;


        .dr-bottom-indi-bottom-title {
          font-size: 1.5rem;
          font-weight: 600;
        }
      }
    }
  }
}


@media screen and (max-width: 1024px) {
  #dr-container {
    position: relative;
    height: auto;
    padding: 2rem 1rem 2rem 1rem;
    margin-top: 0rem;

    #dr-header-cover {
      display: block;
      position: absolute;
      top: -4.5rem; left: 0;
      height: 4.5rem;
      width: 100%;
      background-color: $theme-three;
      box-sizing: border-box;
    }

    #dr-bottom-box {
      .dr-bottom-indi-box {
        height: 20rem;
        margin: 1rem auto 0 auto;
        width: 15rem;
        background-size: cover;
      }
    }
  }
}