$bg: var(--bg);
$theme-one: var(--theme-one);
$theme-two: var(--theme-two);
$theme-three: var(--theme-three);
$theme-four: var(--theme-four);
$theme-five: var(--theme-five);
$theme-six: var(--theme-six);
$theme-seven: var(--theme-seven);

$shadow: var(--shadow);
$shadow-two: var(--shadow-two);

$border-one: var(--border-one);

$footer-one: var(--footer-one);
$footer-two: var(--footer-two);

@font-face {
    font-family: malHead;
    src: url('../../../Assets/Fonts/FML-TT-Malavika_Bold_Italic.ttf');
}

#health-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    // min-height: 100vh;
    height: auto;
    width: 100vw;
    padding: 3rem 5rem 5rem 5rem;
    box-sizing: border-box;

    #health-header-cover {
        display: none;
    }

    #health-title {
        width: 90%;
        font-size: 3rem;
        font-family: fontBold;
        color: $theme-three;
    }

    #health-subtitle {
        padding: 1rem 0;

        .health-sub1 {
            padding: 0 0 0.5rem 0;
            font-size: 1.5rem;
        }
        #health-sub2 {
            font-size: 1.75rem;
            font-family: fontExBold;
        }
    }

    .pkg-container {
        display: flex;
        position: relative;
        min-height: auto;
        height: auto;
        width: 90%;
        margin-top: 2rem;
        border-radius: 1rem;
        box-shadow: 0px 0px 10px 0 $shadow-two;

        &:nth-child(even) {
            .pkg-left {
                order: 2;
            }
            .pkg-right {
                animation-name: pkg-right-two;
                border-radius: 1.5rem 0 0 1.5rem;
                transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;

                .pkg-right-img {
                    border-radius: 1rem 0 0 1rem;
                }
            }
        }

        .pkg-left {
            height: 100%;
            width: 50%;
            padding: 3rem 1rem 3rem 3rem;
            box-sizing: border-box;

            .pkg-title {
                font-size: 1.25rem;
                font-weight: 600;
                // margin-bottom: 0.5rem;
            }
            .pkg-subtitle {
                font-size: 1rem;
                margin-bottom: 1.5rem;
            }
        }
        .pkg-right {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            height: auto;
            width: 50%;
            border-radius: 0 1.5rem 1.5rem 0;
            background-color: grey;
            animation-name: pkg-right-one;
            animation-duration: 1s;
            animation-fill-mode: both;
            transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
            background-position: center;
            background-size: cover;

            .pkg-right-img {
                display: flex;
                align-items: flex-start;
                justify-content: center;
                flex-direction: column;
                height: 100%;
                width: 100%;
                padding: 3rem;
                box-sizing: border-box;
                border-radius: 0 1rem 1rem 0;
                backdrop-filter: blur(5px);
                color: #fff;

                .pkg-right-img-sub1 {
                    // padding: 0 0 0.5rem 0;
                    font-size: 1.25rem;
                }
                .pkg-right-img-sub2 {
                    font-size: 1.75rem;
                    font-family: fontExBold;
                }                
            }
        }

        @keyframes pkg-right-one {
            0% {transform: translateX(100%);opacity: 0;}
            100% {transform: translateX(0); opacity: 1;}
        }
        @keyframes pkg-right-two {
            0% {transform: translateX(-100%); opacity: 0;}
            100% {transform: translateX(0); opacity: 1;}
        }
    }

    #pkg2 {
        text-align: right;
        .pkg-left {
            padding-right: 3rem;
            .pkg-title {
                font-size: 1.5rem;
            }
            .pkg-subtitle {
                font-size: 1.25rem;
            }
        }
        .pkg-right {
            .pkg-right-img {
                align-items: flex-end;
                .pkg-right-img-sub1, .pkg-right-img-sub2 {
                    font-size: 1.75rem;
                }
            }
        }
    }

    #pkg1 {
        .pkg-right {
            background-image: url('../../../Images/Interior/201.jpg');
        }
    }
    #pkg2 {
        .pkg-right {
            background-image: url('../../../Images/Home-one/gFive.jpg');
        }
    }
    #pkg3 {
        .pkg-right {
            background-image: url('../../../Images/Home-one/gFour.jpg');
        }
    }
    #pkg4 {
        .pkg-right {
            background-image: url('../../../Images/Home-one/gOne.jpg');
        }
    }
}

@media screen and (max-width: 1024px) {
    #health-container {
        position: relative;
        // min-height: 100vh;
        height: auto;
        width: 100vw;
        padding: 2rem 0rem 2rem 0rem;
    
        #health-header-cover {
            display: block;
            position: absolute;
            top: -4.5rem; left: 0;
            height: 4.5rem;
            width: 100%;
            background-color: $theme-three;
            box-sizing: border-box;
        }
    
        #health-title {
            font-size: 2.5rem;
            font-family: fontBold;
            color: $theme-two;
            margin: 0 0 2rem 1rem;
        }
    
        #health-subtitle {
            padding: 1rem 0;
    
            .health-sub1 {
                padding: 0 0 0.5rem 0;
                font-size: 1.5rem;
            }
            #health-sub2 {
                font-size: 1.75rem;
                font-family: fontExBold;
            }
        }
    
        .pkg-container {
            display: flex;
            flex-direction: column;
            position: relative;
            min-height: auto;
            height: auto;
            width: 90%;
            border-radius: 1rem;
            margin: 0 auto;
            box-shadow: 0px 0px 10px 0 $shadow-two;
    
            &:nth-child(even) {
                .pkg-left {
                    order: 2;
                }
                .pkg-right {
                    animation-name: pkg-right-two;
                    border-radius: 1rem 1rem 0 0;
    
                    .pkg-right-img {
                        border-radius: 0.5rem 0.5rem 0 0;
                    }
                }
            }
            &:not(&:last-child) {
                margin-bottom: 2rem;
            }
    
            .pkg-left {
                order: 2;
                height: 100%;
                width: 100%;
                padding: 2rem 1rem 2rem 2rem;
                box-sizing: border-box;
    
                .pkg-title {
                    font-size: 1.25rem;
                    font-weight: 600;
                    // margin-bottom: 0.5rem;
                }
                .pkg-subtitle {
                    font-size: 1rem;
                    margin-bottom: 1.5rem;
                }
            }
            .pkg-right {
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                height: auto;
                width: 100%;
                border-radius: 1rem 1rem 0 0;
                background-color: grey;
                animation-name: pkg-right-one;
                animation-duration: 1s;
                animation-fill-mode: both;
                transition: transform 0.5s ease-in-out;
                background-position: center;
                background-size: cover;
    
                .pkg-right-img {
                    display: flex;
                    align-items: flex-start;
                    justify-content: center;
                    flex-direction: column;
                    height: 100%;
                    width: 100%;
                    padding: 2rem;
                    box-sizing: border-box;
                    border-radius: 0.5rem 0.5rem 0 0;
                    backdrop-filter: blur(30px);
                    color: #fff;
    
                    .pkg-right-img-sub1 {
                        // padding: 0 0 0.5rem 0;
                        font-size: 1.25rem;
                    }
                    .pkg-right-img-sub2 {
                        font-size: 1.75rem;
                        font-family: fontExBold;
                    }                
                }
            }
        }
    
        #pkg2 {
            text-align: right;
            .pkg-left {
                padding-right: 2rem;
            }
        }

        .pkg-container {
            .pkg-right {
                .pkg-right-img {
                    .pkg-right-img-sub1, .pkg-right-img-sub2 {
                        margin-bottom: 0.5rem;
                    }
                }
            }
        }
    }
    
}

