$bg: var(--bg);
$theme-one: var(--theme-one);
$theme-two: var(--theme-two);
$theme-three: var(--theme-three);
$theme-four: var(--theme-four);

$border-one: var(--border-one);

$footer-one: var(--footer-one);
$footer-two: var(--footer-two);


#main-container {
  position: relative;
  height: auto;
  // width: 100vw;
  background-color: $bg;
  // overflow-x: hidden;
  z-index: 15;

  #main-screens-container {
    height: auto;
    width: 100%;
  }
}

@media screen and (max-width: 1024px) {
  #main-container {
    width: 100%;
  }
}